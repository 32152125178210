var appdKey = document.head.querySelector("[name~=appKey][content]");
if (appdKey != undefined && appdKey != null) {
	window['adrum-start-time'] = new Date().getTime();
	(function(config) {
		config.appKey = appdKey.content;
		config.adrumExtUrlHttp = 'resources/lib';
		config.adrumExtUrlHttps = 'resources/lib';
		config.beaconUrlHttp = 'http://eum-coll.nasdaq.com';
		config.beaconUrlHttps = 'https://eum-coll.nasdaq.com';
		config.xd = {
			enable : false
		};
		config.angular = true;
		config.spa = {
	            "spa2": true
	        };
	})(window['adrum-config'] || (window['adrum-config'] = {
		xhr: {
            payloadParams: [{method: 'POST'}] //config to filter calls being captured
		},
        userEventInfo: {
			"VPageView" : function() {
				var data = bvUsageUtils.getVPageUsageReportData();
				return {
					userData: data.userDataStrObj,
					userDataBoolean: data.userDataBoolObj,
					userDataDouble: data.userDataDoubleObj,
					userDataLong: data.userDataLongObj,
					userDataDate: data.userDataDateObj
				}
			},
            "Ajax" : function(context) {
				var data = bvUsageUtils.getAjaxUsageReportData(context);
				return {
					userData: data.userDataStrObj,
					userDataBoolean: data.userDataBoolObj,
					userDataDouble: data.userDataDoubleObj,
					userDataLong: data.userDataLongObj,
					userDataDate: data.userDataDateObj
				}
			}
		}
	}));

	if ('https:' === document.location.protocol) {
		document.write(unescape('%3Cscript')
				+ " src='resources/lib/adrum-4.5.12.js' "
				+ " type='text/javascript' charset='UTF-8'"
				+ unescape('%3E%3C/script%3E'));
	} else {
		document.write(unescape('%3Cscript')
				+ " src='resources/lib/adrum-4.5.12.js' "
				+ " type='text/javascript' charset='UTF-8'"
				+ unescape('%3E%3C/script%3E'));
	}
}